(function ($, Drupal) {
  Drupal.behaviors.siteFooterV1 = {
    attach: function (context) {
      var $overrides = $('.js-site-footer-override', context);
      var $banner = $('.js-footer-banner', context);

      $banner.html($overrides.last().html());
    }
  };
})(jQuery, Drupal);
